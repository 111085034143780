.scene-map-controls {
    position: fixed;
    top: 0;
    left: 0;
}

.scene-map-controls .action {
    position: absolute;
    width: 20px;
    height: 20px;

    margin: 10px;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #CCC;
    color: #CCC;
    cursor: pointer;
}

.scene-map-controls .action.map-network-dynamic-element-visibility {
    top: 10px;
}

.scene-map-controls .action.map-network-dynamic-element-visibility.active {
    color: #78BA4D;
}

.scene-map-controls .action .icon {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.scene-map-controls .action.map-network-dynamic-element-visibility.active .icon {
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.scene-map-controls .action.map-network-dynamic-element-visibility.active .icon {}
