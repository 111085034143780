body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.popup-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 200px;
    height: auto;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 15px;
    border-radius: 4px;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    -webkit-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
    z-index: 9999;
}

.popup-info:before {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    line-height: 0;
    border-width: 8px 8px 0 8px;
    border-color: rgba(0, 0, 0, 0.65) transparent transparent;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    bottom: -8px;
    left: calc(50% - 8px);
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transition: .3s;
    transition: .3s;
}

.popup-info .header .close {
    color: #ffffff;
    position: absolute;
    top: 2px;
    right: 9px;
    cursor: pointer;
    font-size: 18px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.popup-info .content {
    overflow: hidden auto;
    max-height: 100%;
}

.popup-info .content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.popup-info .content::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.popup-info .content::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.popup-info .content .parameters {
    margin: 5px 0;
}

.popup-info .header {
    margin-bottom: 5px;
}

.popup-info .header .title {
    margin-right: 10px;
}

.scene-map-hotspot {
    position: absolute;
}

.scene-map-hotspot .scene-map-hotspot-pin {
    position: absolute;
    margin: 0;
    background-color: transparent;
    width: 40px;
    height: 60px;
    cursor: pointer;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.scene-map-hotspot .scene-map-hotspot-pin-icon {
    width: 100%;
    height: 100%;
}

.scene-map-hotspot .scene-map-hotspot-pin.white {
    color: white;
}

.scene-map-hotspot.capacity-low .scene-map-hotspot-pin,
.scene-map-hotspot.capacity-low .popup-info .header .title {
    color: #78BA4D;
}

.scene-map-hotspot.capacity-medium .scene-map-hotspot-pin,
.scene-map-hotspot.capacity-medium .popup-info .header .title {
    color: #F49A2D;
}

.scene-map-hotspot.capacity-high .scene-map-hotspot-pin,
.scene-map-hotspot.capacity-high .popup-info .header .title {
    color: #E83C33;
}

.scene-map-hotspot .popup-info {
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -70px);
    transform: translate(-50%, -70px);
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.loader-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .95);
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loader-circle {
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 2px inset;

    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
}

.loader-line {
    width: 80px;
    height: 80px;
    box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 2px inset;
    border-radius: 50%;
}

.loader-line-mask {
    width: 40px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    -webkit-transform-origin: 40px 40px;
    transform-origin: 40px 40px;

    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    -webkit-animation: rotate 1.2s infinite linear;
    animation: rotate 1.2s infinite linear;
}

.loader-line {
    border-radius: 50%;
}

.loader-logo {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    color: #78BA4D;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.scene-map-controls {
    position: fixed;
    top: 0;
    left: 0;
}

.scene-map-controls .action {
    position: absolute;
    width: 20px;
    height: 20px;

    margin: 10px;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #CCC;
    color: #CCC;
    cursor: pointer;
}

.scene-map-controls .action.map-network-dynamic-element-visibility {
    top: 10px;
}

.scene-map-controls .action.map-network-dynamic-element-visibility.active {
    color: #78BA4D;
}

.scene-map-controls .action .icon {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.scene-map-controls .action.map-network-dynamic-element-visibility.active .icon {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.scene-map-controls .action.map-network-dynamic-element-visibility.active .icon {}

