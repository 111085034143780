.scene-map-hotspot {
    position: absolute;
}

.scene-map-hotspot .scene-map-hotspot-pin {
    position: absolute;
    margin: 0;
    background-color: transparent;
    width: 40px;
    height: 60px;
    cursor: pointer;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    -o-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.scene-map-hotspot .scene-map-hotspot-pin-icon {
    width: 100%;
    height: 100%;
}

.scene-map-hotspot .scene-map-hotspot-pin.white {
    color: white;
}

.scene-map-hotspot.capacity-low .scene-map-hotspot-pin,
.scene-map-hotspot.capacity-low .popup-info .header .title {
    color: #78BA4D;
}

.scene-map-hotspot.capacity-medium .scene-map-hotspot-pin,
.scene-map-hotspot.capacity-medium .popup-info .header .title {
    color: #F49A2D;
}

.scene-map-hotspot.capacity-high .scene-map-hotspot-pin,
.scene-map-hotspot.capacity-high .popup-info .header .title {
    color: #E83C33;
}

.scene-map-hotspot .popup-info {
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -70px);
    -moz-transform: translate(-50%, -70px);
    -ms-transform: translate(-50%, -70px);
    -o-transform: translate(-50%, -70px);
    transform: translate(-50%, -70px);
}
