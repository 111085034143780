.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.loader-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .95);
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loader-circle {
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    -webkit-box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 2px inset;
    -moz-box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 2px inset;
    box-shadow: rgba(255, 255, 255, 0.1) 0 0 0 2px inset;

    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.loader-line {
    width: 80px;
    height: 80px;
    -webkit-box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 2px inset;
    -moz-box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 2px inset;
    box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 2px inset;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.loader-line-mask {
    width: 40px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    -webkit-transform-origin: 40px 40px;
    -moz-transform-origin: 40px 40px;
    -ms-transform-origin: 40px 40px;
    -o-transform-origin: 40px 40px;
    transform-origin: 40px 40px;

    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    -webkit-animation: rotate 1.2s infinite linear;
    -o-animation: rotate 1.2s infinite linear;
    animation: rotate 1.2s infinite linear;
}

.loader-line {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.loader-logo {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    color: #78BA4D;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
