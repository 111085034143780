.popup-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 200px;
    height: auto;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
    -webkit-transform: translate(-50%, -10px);
    -moz-transform: translate(-50%, -10px);
    -ms-transform: translate(-50%, -10px);
    -o-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
    z-index: 9999;
}

.popup-info:before {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    line-height: 0;
    border-width: 8px 8px 0 8px;
    border-color: rgba(0, 0, 0, 0.65) transparent transparent;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    bottom: -8px;
    left: calc(50% - 8px);
    transform: rotate(360deg);
    transition: .3s;
}

.popup-info .header .close {
    color: #ffffff;
    position: absolute;
    top: 2px;
    right: 9px;
    cursor: pointer;
    font-size: 18px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.popup-info .content {
    overflow: hidden auto;
    max-height: 100%;
}

.popup-info .content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.popup-info .content::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.popup-info .content::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.popup-info .content .parameters {
    margin: 5px 0;
}

.popup-info .header {
    margin-bottom: 5px;
}

.popup-info .header .title {
    margin-right: 10px;
}
